<template>
  <b-card no-body>
    <b-card-body>
      <b-row>
        <b-col>
          <v-select
            v-model="dataQuery.id_service_statuses"
            :options="serviceStatuses"
            label="title"
            :reduce="item => item.id"
            @input="getDataList"
          />
        </b-col>
        <b-col cols="auto">
          <b-button
            variant="success"
            target="_blank"
            :href="downloadUrl"
          >
            <FeatherIcon icon="DownloadIcon" />
            Excel Aktar
          </b-button>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col>
          <b-form-group
            label="Başlangıç Tarihi"
            label-for="sdate"
          >
            <b-form-datepicker
              id="sdate"
              v-model="dataQuery.sdate"
              v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
              locale="tr"
              start-weekday="1"
              @input="getDataList"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Bitiş Tarihi"
            label-for="edate"
          >
            <b-form-datepicker
              id="edate"
              v-model="dataQuery.edate"
              v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
              locale="tr"
              start-weekday="1"
              @input="getDataList"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-body>
    <b-table
      responsive="sm"
      :fields="fields"
      :items="dataList"
      striped
    >
      <template #cell(company)="data">
        <div>{{ data.item.company }}</div>
        <div class="font-small-2 text-warning">
          {{ data.item.sdate? moment(data.item.sdate).format('llll') : '' }} -
          {{ data.item.edate? moment(data.item.edate).format('llll') : '' }}
        </div>
        <div class="font-small-2 text-muted">
          {{ data.item.username }}
        </div>
      </template>
      <template #cell(service_type)="data">
        <div class="font-small-3 font-weight-bold">
          {{ data.item.service_type }}
        </div>
        <div class="font-small-2 text-success">
          {{ data.item.service_status }}
        </div>
      </template>
      <template #cell(lines)="data">
        <b-button
          variant="primary"
          size="sm"
          @click="setDetailModalData(data.item)"
        >
          <FeatherIcon icon="EyeIcon" />
          Görüntüle
        </b-button>
      </template>
      <template #cell(control)="data">
        <div class="text-right">
          <b-button
            v-if="data.item.id_service_statuses === '3'"
            variant="success"
            size="sm"
            @click="changeStatus(data.item.id,4)"
          >
            <FeatherIcon icon="CheckIcon" />
            Fatura Kesildi
          </b-button>
          <b-button
            v-else-if="data.item.id_service_statuses === '4'"
            variant="danger"
            size="sm"
            @click="changeStatus(data.item.id,3)"
          >
            <FeatherIcon icon="ChevronLeftIcon" />
            Geri Al
          </b-button>
          <b-button
            v-else
            variant="warning"
            size="sm"
            disabled
            block
          >
            <FeatherIcon icon="ClockIcon" />
            Beklemede
          </b-button>
        </div>
      </template>
    </b-table>
    <b-card-footer>
      <b-pagination
        v-model="currentPage"
        :total-rows="dataCounts"
        :per-page="perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
    </b-card-footer>
    <b-modal
      v-model="detailModal"
      title="İşlem Detayı"
      hide-footer
      no-close-on-backdrop
      scrollable
      size="lg"
    >
      <div class="font-weight-bold text-primary">
        Müşteri Şikayetleri
      </div>
      <div v-html="detailModalData.complaints" />
      <hr>
      <div class="font-weight-bold text-primary">
        Yapılan Uygulama
      </div>
      <div v-html="detailModalData.transactions" />
      <template v-if="detailModalData.lines.length">
        <hr>
        <div class="font-weight-bold text-primary">
          Yedek Parça
        </div>
        <b-table
          class="mt-1"
          :items="detailModalData.lines"
          :fields="modalFields"
          bordered
          striped
        >
          <template #cell(quantity)="data">
            <span>{{ data.item.quantity }} {{ data.item.unit }}</span>
          </template>
        </b-table>
      </template>
    </b-modal>
  </b-card>
</template>
<script>
import {
  BCard, BCardBody, BTable, BPagination, BButton, BCardFooter, BModal, BRow, BCol, BFormDatepicker, BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'CustomersIndex',
  components: {
    BCard,
    BCardBody,
    BTable,
    BPagination,
    BButton,
    BCardFooter,
    BModal,
    BRow,
    BCol,
    BFormDatepicker,
    BFormGroup,
    vSelect,
  },
  data() {
    return {
      detailModal: false,
      detailModalData: {
        lines: [],
      },
      currentPage: 1,
      perPage: 20,
      modalFields: [
        {
          key: 'product',
          label: 'Parça Adı',
        },
        {
          key: 'quantity',
          label: 'Miktar',
        },
      ],
      fields: [
        {
          key: 'company',
          label: 'FİRMA ADI',
        },
        {
          key: 'service_type',
          label: 'İŞLEM TİPİ',
          thClass: 'text-nowrap width-100',
          tdClass: 'text-nowrap width-100',
        },
        {
          key: 'lines',
          label: 'DETAY',
          thClass: 'text-nowrap width-100',
          tdClass: 'text-nowrap width-100',
        },
        {
          key: 'control',
          label: '',
          thClass: 'text-nowrap width-100',
          tdClass: 'text-nowrap width-100',
        },
      ],
      dataQuery: {
        id_service_statuses: '3',
        sdate: null,
        edate: null,
        limit: 20,
        start: 0,
      },
    }
  },
  computed: {
    downloadUrl() {
      let url = `${this.$store.state.app.baseURL}/Exports/reports/services?token=${localStorage.getItem('downloadToken')}`
      if (this.dataQuery.sdate) {
        url += `&sdate=${this.dataQuery.sdate}`
      }
      if (this.dataQuery.edate) {
        url += `&edate=${this.dataQuery.edate}`
      }
      return url
    },
    dataList() {
      return this.$store.getters['serviceAccountingReports/dataList']
    },
    dataCounts() {
      return this.$store.getters['serviceAccountingReports/dataCount']
    },
    serviceStatuses() {
      return this.$store.getters['serviceStatuses/dataList']
    },
  },
  watch: {
    currentPage(val) {
      this.dataQuery.start = (val - 1) * 20
      this.getDataList()
    },
  },
  created() {
    this.getServiceStatuses()
    this.getDataList()
  },
  methods: {
    getServiceStatuses() {
      this.$store.dispatch('serviceStatuses/getDataList', {
        select: [
          'service_statuses.id AS id',
          'service_statuses.title AS title',
        ],
      })
    },
    setDetailModalData(data) {
      this.detailModalData = data
      this.detailModal = true
    },
    getDataList() {
      this.$store.dispatch('serviceAccountingReports/getReport', this.dataQuery)
    },
    changeStatus(id, status) {
      let message = 'Kayıt Fatura Edildi olarak güncellenecektir! Devam etmek istiyor musunuz?'
      if (status === 3) {
        message = 'Kayıt İşlem Tamamlandı olarak güncellenecektir! Devam etmek istiyor musunuz?'
      }
      this.$swal({
        title: 'Uyarı!',
        text: message,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Devam Et',
        cancelButtonText: 'Vazgeç',
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('serviceAccountingReports/changeStatus', {
            id,
            id_service_statuses: status,
          })
            .then(res => {
              if (res) {
                this.$swal({
                  icon: 'success',
                  title: 'İşlem Başarılı!',
                  text: 'Kayıt Başarıyla Güncellenmiştir.',
                  confirmButtonText: 'Kapat',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.getDataList()
              }
            })
        }
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
